@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import './_colors';

//remove default cursor pointer color
:root {
  -webkit-tap-highlight-color: transparent;
}

html.cdk-global-scrollblock {
  position: static !important;
  width: auto !important;
  overflow-y: visible !important;
}

// Define custom typography configuration
$custom-typography: mat.define-typography-config($font-family: "'Inter'");

// Apply typography hierarchy
@include mat.typography-hierarchy($custom-typography);

// Define color palettes
$app-primary: mat.define-palette($custom-brand-primary);
$app-accent: mat.define-palette($custom-brand-accent);
$app-warn: mat.define-palette($custom-brand-warn);
$brand-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn
    )
  )
);

// Include the Angular Material theme styles
@include mat.all-component-themes($brand-light-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter';
  background: #FFFFFF;
}

//dropdown arrow roatation
.arrow-down {
  transform: rotate(0deg);

  &.open {
    transform: rotate(180deg);
  }
}

.mdc-button__ripple {
  display: none;
}

.cdk-overlay-pane {
  .mat-mdc-select-panel {
    box-shadow: 0px 4px 6px -2px #1018280D, 0px 12px 16px -4px #1018281A, 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px !important;
    overflow-x: hidden;
    padding: 0;
  }
}
.no-border {
  iframe {
    border: none;
  }
}