:root {
  --primary-color: #00b886;
  --secondary-color: #ffe058;
  --primary-light-color: #8ad4b7;
  --secondary-light-color: #F8EA60;
  --color4: #f1f1f1;
  --color5: #1f1f1f;
  --color6: #626262;
  --color7: #878787;
  --color8: #F4F4F4;
  --text-black: #111111;
  --border-color: #E9E9E9;
  --text-grey: #828282;
  --header-height: 80px;
  --white: #FFFFFF;
  --mobile-header-height: 178px;
}